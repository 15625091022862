<template>
  <div class="un-found-page">
    <h1>TERMS & CONDITIONS</h1>
    <p>
      Please read the T&Cs below, together with our privacy policy, carefully
      before using our service
    </p>
    <p>
      These Terms of Service define the terms by which you may use the LPMAC
      Resident and lpmac.com.au website (including their various subdomains)
      (“Website”) and related software service (the “Service”) and are an
      agreement between you and Leon’s Property Maintenance and Care Pty Ltd
      (ACN 149 342 085) trading as LPMAC Resident (the Company, Website and
      Service are collectively referenced as “we” or “us”). By using this
      Website and Service, you expressly acknowledge that you have read, accept,
      and agree to be bound by these Terms of Service, as well as any applicable
      laws hereunder. If you do not agree to these Terms of Service, you should
      not use this Website and/or Service.
    </p>
    <p class="tc_tittle">Our Service</p>
    <p>
      Our Service enables users to efficiently manage their residential strata
      buildings. The Service is intended for use by Building Facilities
      Managers, Property Manager and Strata Management professionals within an
      organisation and residents. The Service combines the simple to use and
      durable mobile working solution with a remodelled high performance back
      -end system optimised to provide a convenient package covering the full
      spectrum of tasks that building managers, concierge staff and supervisors
      need to successfully manage day to day requirements of a residential
      building complex.
    </p>
    <p class="tc_tittle">Registration</p>
    <p>
      You do not have to register in order to visit our Website. In order to
      access our Service, however, an administrative use will initially need to
      register, create an account, and become a subscriber. When you register,
      you will be asked to provide personal information as outlined in our
      Privacy Policy. Please read our Privacy Policy for details about how we
      collect, use and store your private information.
    </p>
    <p>
      When you register, you need to create a password. You should not disclose
      your password to any third party. You are solely responsible for keeping
      your password confidential and for the activity that occurs on your
      account. We will never ask you to send your password or other sensitive
      information to us in an email. If you suspect any unauthorised use of your
      account, contact us immediately.
    </p>
    <p>
      All information provided by you in your registration must be truthful and
      complete. We may suspend your registration with notice if we have any
      reason to believe that you have provided us with false information. Only
      adults, who are at least eighteen (18) years of age, are eligible to
      register and use our Website and Service. In addition, you must be fully
      competent to enter and comply with the terms, conditions, obligations,
      representations, and warranties set forth in these Terms of Service. By
      registering and using our Website and Service, you represent and warrant
      that you have the right, authority, and capacity to enter these Terms of
      Service and can abide by all the terms and conditions set forth therein.
    </p>
    <p class="tc_tittle">Subscription Terms</p>
    <p>1. Access</p>
    <p>
      We grant access to use our System on per-organisation basis. The initial
      Client account created for an organisation becomes a “System
      Administrator” account, with the ability to create building manager
      accounts within your organisation.
    </p>
    <p>2. Restrictions</p>
    <p>
      You may not share, sell, rent, resell, lease, sublicense, or otherwise
      permit any third party to access, use, or display our Services. The
      Services and the underlying software platform (the “Software” contain our
      trade secrets and in order to protect those trade secrets, you agree not
      to take any action to reverse engineer, compile, translate, disassemble,
      copy or create derivative works of the Software in whole or in part, nor
      to permit any third party to do so.
    </p>
    <p>3. Cancellation</p>
    <p>
      You may cancel your subscription at any time after the term contract has
      expired by closing your account or contacting us via email or phone.
    </p>
    <p class="tc_tittle">Operation of our Website and Service</p>
    <p>
      We make commercially reasonable efforts to maintain our Website and
      Service and to keep them operating on a 24/7 basis, free from bugs,
      errors, technical problems, or defects. If, at any time in the future, we
      identify any bugs, errors, technical problems, or defects, then we will
      assign technicians to address and resolve the issue(s).
    </p>
    <p>
      If you become aware of an error, bug, or other technical problem, then you
      should notify us immediately and provide us with the following
      information:
    </p>
    <p>
      1. Description of the Incident – The specific sequence of events which
      generated the incident and a full description.
    </p>
    <p>
      2. Description of Error Message – The exact wording of any error
      messages, if applicable.
    </p>
    <p>
      3. Description of Discovery of Incident – Any special circumstances
      surrounding the discovery of the incident which you are seeking technical
      support.
    </p>
    <p>
      We cannot guarantee that you access to the Website or Service will be
      uninterrupted, or that the Website and Service will be always available.
      We disclaim any liability or responsibility for any delay, interruption,
      or downtime. We make commercially reasonable efforts to ensure that our
      Website and Service are protected from viruses, malware and other
      destructive software, but we cannot guarantee that either will always be
      free from viruses. We urge you to use reasonable care in downloading
      information. We can assume no responsibility for any damage to computer
      equipment or other property that may result from the use of the Website
      and Service, or as a result of downloading from the Website and Service.
    </p>

    <p class="tc_tittle">Acceptable Use Policy</p>
    <p>
      Our website and Service should only be used for lawful purposes. We
      specifically prohibit any other use of the Website and Service, including
      but not limited to the following:
    </p>
    <p>
      1. Posting or submitting to the Website and Service any information in
      which you impersonate or claim to be any third party, or in which you
      misrepresent your affiliation with another person or entity.
    </p>
    <p>
      2. Disclosing or sharing your password with any third party or allowing
      such third-party access to the password-protected features of the Service.
    </p>
    <p>
      3. Sending unsolicited emails or other communications through our Service
      to third parties.
    </p>
    <p>
      4. Posting content or initiating communications with are unlawful,
      libellous, abusive, obscene, discriminatory, or otherwise objectionable.
    </p>
    <p>5. Using the Website or Service for any illegal purpose.</p>
    <p>6. Uploading or sending infringing content through the Service.</p>
    <p>
      7. Taking any action that disrupts, tampers with, interferes, or imposes
      an unreasonable burden on the Website’s or our software platform’s
      infrastructure, servers, data or network or those of any third party via
      our Service.
    </p>
    <p>
      8. Using or attempting to use any engine, software tool, agent, or other
      device or mechanism (including without limitation, browsers, spiders,
      robots, avatars, or intelligent agents) to navigate or search the Website
      or Service other than generally available third party browsers (including
      but not limited to Internet Explorer, Firefox, Safari, and Google Chrome)
    </p>
    <p>
      10. Uploading any content that contains software viruses or any other
      computer code, files, or programs designed to interrupt, destroy, or limit
      functionality of the Website or Service, or of any computer software,
      hardware, or telecommunications equipment.
    </p>
    <p>
      11. Deciphering, decompiling, disassembling, copying, duplicating,
      aggregating, or reverse engineering any of the software, content,
      information, or other materials comprising or in any way making up part of
      the Website or Service.
    </p>
    <p>
      12. Using any means of automatically searching or mining data from the
      Website or Service, or in any way attempting to interfere with the proper
      working of the Website or Service.
    </p>
    <p>
      13. Attempting to scrape or access the Service through automated means
      other than an official API that we provide you.
    </p>
    <p>
      We reserve the right to suspend or cancel the account of any user who does
      not appropriately use the Website or Service. If you become aware of any
      inappropriate use of our Website or Service, please notify us by email
      at info@lpmac.com.au
    </p>
    <p class="tc_tittle">Intellectual Property Infringement Complaints</p>
    <p>
      We respect the intellectual property rights of others. Materials contained
      on the Website are protected under copyright and other laws of Australia
      and under international conventions and similar laws abroad. If you ever
      suspect that your intellectual property has been copied in any manner that
      constitutes intellectual property infringement, which is in any way
      relevant to this Website or Service, then please notify us immediately,
      providing the following information:
    </p>
    <p>
      1. The name of the owner of the intellectual property and a signature of
      the person authorised to act on behalf of the intellectual property
      interest, which is alleged to be infringed.
    </p>
    <p>
      2. A description of the intellectual property right that you claim has
      been infringed.
    </p>
    <p>
      3. A description of where the allegedly infringing item is located on the
      Website or Service.
    </p>
    <p>4. Your address, telephone number, and email address.</p>
    <p>
      5. A statement by you confirming that you are the intellectual property
      owner or are authorised to act on the intellectual property owner’s
      behalf, made under penalty of perjury.
    </p>
    <p>
      Please contact us with matters relating to Intellectual Property
      Infringement Complaints by email at info@lpmac.com.au
    </p>
    <p class="tc_tittle">Privacy</p>
    <p>
      LPMAC Resident Privacy Policy, available online here, is hereby
      incorporated by reference.
    </p>
    <p class="tc_tittle">Third Parties</p>
    <p>
      Your dealings with any third party with whom you connect or do business
      through our Service are solely between you and such third party. Our
      Service provides the tools by which you can communicate effectively and
      professionally which such third parties, but we are not a party to any
      agreement that you enter into with a third party through our Service, nor
      are we in any way involved with such third party relationships.
    </p>
    <p>
      You are solely responsible or liable for exercising common sense and
      reasonable caution in any dealing with any third-party business,
      individual, or platform, which whom you are connected through this
      Service. You agree that we will not be responsible or liable for any loss,
      damage, or other liabilities incurred as a result of your interactions
      with such third parties. You assume the sole risk of loss and liability
      for your interaction with any third party. In the event that you ever
      have a complaint against such third party, you should contact such third
      party directly regarding your issues.
    </p>
    <p class="tc_tittle">Feedback; Idea Submissions</p>
    <p>
      We are pleased to hear from you and welcome your feedback about our
      Website and Services. If you provide any feedback to us, you agree that
      all such feedback will not be subject to any obligation of confidence by
      us, and that we will not be liable for any use or disclosure of the
      feedback. Also, you agree that we may use any such feedback to make
      improvements to our Website and Services at our sole discretion without
      any obligation to you.
    </p>
    <p>
      In the event that you submit any ideas to us about the Website or our
      Service, you grant us an exclusive, perpetual, royalty-free, transferable,
      worldwide license with the right to grant sublicenses to use, display,
      copy, publish, republish and to incorporate into our Intellectual Property
      your idea.
    </p>
    <p class="tc_tittle">Governing Law; Dispute Resolution</p>
    <p>
      The Website and our Service are controlled by Leon’s Property Maintenance
      and Care Pty Ltd (ACN 149 342 085) trading as LPMAC Resident in Australia.
      These Terms of Service and any agreements with LPMAC Resident shall be
      governed and construed in accordance with the laws of Australia. All
      disputed arising under this Agreement will be heard in VIC and resolved in
      accordance with Australian law.
    </p>
    <p class="tc_tittle">Contacting Us</p>
    <p>
      In the event that you have any questions about these Terms of Service, or
      that you need further assistance with respect to the access or use of the
      Website or Services, please contact us at:
    </p>
    <p>LPMAC Resident Team</p>
    <p>Address: G07/12 Cato Street Hawthorn East VIC 3123</p>
    <p>Phone:</p>
    <p>Email:info@lpmac.com.au</p>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "UnFound",
  data() {
    return {};
  },
  computed: {
    ...mapState("bar", {
      nav: "navBarShow",
      side: "sideBarShow",
    }),
  },
  created() {
    this.backup = { nav: this.nav, side: this.side };
    // 切换side
    this.navBarShow(false);
    this.sideBarShow(false);
  },
  destroyed() {
    this.navBarShow(this.backup.nav);
    this.sideBarShow(this.backup.side);
  },
  methods: {
    ...mapMutations("bar", ["navBarShow", "sideBarShow"]),
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
